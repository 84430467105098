body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $body-bg;
  color: darken($white, 50);

  &.darken {
    background: #000;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  color: $primary;
}

footer {
  width: 100%;
  text-align: center;
  color: white;
  background: darken($secondary, 5);
  display: flex;

  body.darken & {
    background: darken($secondary, 10);
  }

  p {
    padding: .5rem 2rem;
  }
}
