.App {
  text-align: center;
  width: 90%;
  min-height: calc(100vh - 67px);
  margin: auto;

  .App-header {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    font-size: calc(10px + 1vmin);
    color: white;

    h1.title {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 auto;
      margin: 0;
      padding: 0;
      width: auto;
      color: $orange;

      body.darken {
        color: #111;
      }

      sup {
        font-size: 1rem;
        color: $black;
      }
    }

    .social-share {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 auto;
      justify-content: right;
      width: auto;
      margin: 0;
      padding: 10px 0 0;

      li {
        list-style-type: none;
        margin: 0 calc(10px + 0.5vmin);
        padding: 0;
        display: inline-block;
      }
    }
  }

  #time-section {
    margin-top: 20vh;

    p {
      time {
        display: flex;
        font-weight: bold;
        font-size: calc(12vmin + 40px);
        color: $primary;

        body.darken & {
          color: #111;
        }

        @include media(desktop) {
          font-size: calc(20vmin + 40px);
        }

        @include media(largeDesktop) {
          font-size: calc(30vmin + 40px);
        }

        .number {
          display: flex;
          width: 30%;
          justify-content: center;
        }

        .sep {
          display: flex;
          width: auto;
          justify-content: center;
          font-weight: 600;
        }
      }
    }
  }
}
