@each $name,
$value in $colors {
  .bg-#{$name} {
    background-color: #{$value} !important;
  }
  .text-#{$name} {
    color: #{$value} !important;
  }
}

@mixin gradient-text($angle: 45deg, $primary: #FFF, $color: #f82, $start: 0, $end: 70%) {
  color: $color;
  background: -webkit-linear-gradient($angle, $primary $start, $color $end);
  background: -o-linear-gradient($angle, $primary $start, $color $end);
  background: -moz-linear-gradient($angle, $primary $start, $color $end);
  background: linear-gradient($angle, $primary $start, $color $end);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

@mixin border-bottom($color) {
  &:after {
    border-width: 50px 0 0 99vw;
    border-color: transparent;
    border-style: solid;
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    z-index: 2;
    border-left-color: $color;
  }
}

@mixin rotate($angle) {
  -webkit-transform: rotate($angle);
  -moz-transform: rotate($angle);
  -ms-transform: rotate($angle);
  -o-transform: rotate($angle);
  transform: rotate($angle);
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin horizontal-list {
  @include reset-list;

  li {
    display: inline-block;
    margin: {
      left: -2px;
      right: 2em;
    }
  }
}

@mixin clip-top {
  overflow: hidden;
  -webkit-clip-path: polygon(0 0, 100% calc(0% + 12vw), 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% calc(0% + 12vw), 100% 100%, 0 100%);
  background: #DDD;
  display: block;
  content: "";
  position: absolute;
  z-index: 0;
  top: 25vh;
  left: 0;
  height: 35%;
  width: 100%;
}

@mixin clip-bottom {
  overflow: hidden;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% calc(0% + 11vw), 0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% calc(0% + 11vw), 0 100%);
  background: #CCC;
  display: block;
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 25vh;
  left: 0;
  height: 35%;
  width: 100%;
}


//media queries
@mixin media($point) {
  @if $point == mobileOnly {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point == mobileLandscape {
    @media (min-width: 440px) {
      @content;
    }
  } @else if $point == xsMobileOnly2 {
    @media (max-width: 374px) {
      @content;
    }
  } @else if $point == xsMobileOnly {
    @media (max-width: 543px) {
      @content;
    }
  } @else if $point == xsMobile {
    @media (min-width: 544px) {
      @content;
    }
  } @else if $point == tabletPortrait {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == tabletToDesktop {
    @media (min-width: 768px) and (max-width: 1200px) {
      @content;
    }
  } @else if $point == tabletOnly {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $point == realTabletDimensions {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point == mobileToRealTablet {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == fromRealTablet {
    @media (min-width: 1025px) {
      @content;
    }
  } @else if $point == mobileToTablet {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $point == smallDesktopDown {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point == smallDesktop {
    @media (min-width: 992px) and (max-width: 1200px) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == fromSmallDesktop {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == largeDesktop {
    @media (min-width: 1600px) {
      @content;
    }
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
