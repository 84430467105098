//
// variables.scss
//

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
// Color
$white:                     #ffffff;

$primary:                   rgba(155, 187, 56, 1);
$secondary:                 rgba(17, 24, 39, 1);
$success:                   #2eca8b;
$warning:                   #f17425;
$info:                      #17a2b8;
$danger:                    #e43f52;
$dark:                      #3c4858;
$black:                     #161c2d;
$muted:                     #8492a6;
$light:                     #f8f9fc;
$pink:                      #f27490;
$coffee:                    #8D340E;
$orange:                    $primary;

// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d;
$gray-700:                  #495057;
// Overlay
$overlay:                   rgba(35, 37, 47, 0.7);
$gradident-overlay:         rgba(47, 86, 212, 0.75);
// Body Background
$body-color:                $white;
$body-bg:                   $secondary;
$shadow:                    0 0 3px rgba(0,0,0,.15);
// Base font
$font-size-base:            16px;

$font-family-base:          'Nunito', sans-serif;
$font-family-secondary:     'Nunito', sans-serif;

$colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "info": $info,
  "danger": $danger,
  "dark": $dark,
  "muted": $muted,
  "light": $light,
  "orange": $primary,
  "pink": $pink,
  "coffee": $coffee,
)
